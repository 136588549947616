.header {
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 0;
  }

  &__logo {
    display: inline-flex;
    margin-right: auto;

    img {
      height: 20px;
    }
  }

  &__btn {
    margin-left: 10px;
  }

  @media (min-width: 1200px) {
    &__inner {
      padding: 50px 0;
    }

    &__logo {
      padding: 5px 0;

      img {
        height: 35px;
      }
    }

    &__btn {
      display: none;
    }
  }
}

.lang {
  display: flex;

  &__item {
    display: inline-flex;

    &:not(:last-child) {
      margin-right: 10px;
    }

    img {
      height: 18px;
    }
  }
}

.news-slider {
  width: calc(100% + 2px);
  position: relative;
  z-index: 1;
  margin: 0 -1px;

  &__prev,
  &__next {
    display: flex;
    width: 25px;
    height: 247px;
    justify-content: center;
    align-items: center;
    background-color: rgba(#000, 0.3);
    border-radius: 4px 0 0 0;
    color: $white;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 1px;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    border-radius: 0 4px 0 0;
    left: auto;
    right: 0;
  }

  .swiper-slide {
    padding: 0 1px;
  }
}

.thumb-slider {
  &__frame {
    @include frame("rect", 100%);
    cursor: pointer;
  }

  &__nav {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 30px;
    line-height: 24px;

    &:focus {
      outline: 0;
    }
  }
}

.preview-slider {
  &__frame {
    display: block;

    img {
      width: 100%;
      height: auto;
      max-height: 100vh;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  .swiper-slide {
    background-color: $white;
  }
}

.info-slider {
  margin-top: 12px;

  .swiper-slide {
    background-color: $white;
  }
}

.pagination {
  margin-bottom: 0;

  .page-item {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  .page-link {
    color: $gray-800;
    padding: 8px 16px;
    border-radius: 4px;
  }
}

.breadcrumb {
  &-item {
    &,
    & + &::before,
    &.active,
    &.active::before,
    a,
    a:hover {
      color: $gray-800;
    }
  }
}

$phoneRingColor: #e73a35;
.phone-ring {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background-color: $phoneRingColor;
  position: fixed;
  z-index: 100;
  bottom: 50px;
  left: 50px;
  box-shadow: 0 5px 20px rgba(#000, 0.3);

  &:hover {
    color: $white;
  }

  &::before {
    content: '';
    display: block;
    width: 250%;
    height: 250%;
    border-radius: 50%;
    border: 1px solid $phoneRingColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: phone-circle-animate 1s ease-in-out infinite;
  }

  &::after {
    content: '';
    display: block;
    width: 160%;
    height: 160%;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: rgba($phoneRingColor, 0.5);
    animation: phone-circle-fill-animate 2s ease-in-out infinite;
  }

  &__icon {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgba($phoneRingColor, 0.7);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: phone-icon-animate 1s ease-in-out infinite;
  }

  &__text {
    display: inline-block;
    background-color: rgba($phoneRingColor, 0.7);
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    padding: 0 20px 0 60px;
    color: $white;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    box-shadow: 0 5px 20px rgba(#000, 0.3);
  }

  &:hover &__text {
    color: $phoneRingColor;
    background-color: rgba(#fff, 0.7);
  }

  @media (max-width: 767px) {
    left: 20px;
    bottom: 20px;

    &__text {
      display: none;
    }
  }
}

@keyframes phone-circle-animate {
  0% {
    transform: translate(-50%, -50%) rotate(0) scale(0.5);
    opacity: 0.1;
  }
  30% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.1;
  }
}

@keyframes phone-circle-fill-animate {
  0%,
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.2;
  }
  50% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.2;
  }
}

@keyframes phone-icon-animate {
  0%,
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20%,
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    border-bottom: 2px solid $border-color;
    padding-bottom: 20px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.exhibition {
  &__frame {
    @include frame('rect', 43%);
    @include frame('zoom');
  }

  &__title {
    font-size: 30px;
    font-weight: 400;
    margin: 20px 0 14px;
  }

  &__info {
    font-size: 16px;
    color: #999;
    margin-bottom: 16px;
  }

  &__desc {
    @include max-line(3);
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 18px;
    }

    &__info {
      font-size: 14px;
    }
  }
}

.news {
  &__frame {
    @include frame('rect', 66.66%);
    border-radius: 4px 4px 0 0;
  }

  &__body {
    padding: 20px;
    border: 1px solid $border-color;
    border-top: 0;
    border-radius: 0 0 4px 4px;
  }

  &__title {
    font-size: 20px;
    margin-bottom: 8px;
  }

  &__desc {
    @include max-line(2);
    margin-bottom: 10px;
  }

  &__info {
    color: #999;
  }
}

.contact {
  &__title {
    font-size: 40px;
    margin-bottom: 28px;
  }

  &__info {
    font-size: 20px;
  }

  &__subtitle {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &__socials {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  &__social {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: #333;
    margin-right: 50px;

    &:hover {
      color: #000;
      text-decoration: none;
    }

    .fa {
      font-size: 1.4em;
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 30px;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 24px;
      margin: 30px 0 16px;
    }

    &__socials {
      margin-bottom: 20px;
    }

    &__social {
      font-size: 16px;
    }

    &__info {
      font-size: 14px;
    }
  }
}

.ct-form {
  &__block {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 30px;
    margin-bottom: 24px;
  }

  &__map {
    height: 350px;
    flex-grow: 1;
    border: 1px solid $border-color;
  }

  &__btn {
    border: 0;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 50px;
    padding: 0 80px;
    border-radius: 4px;
    background-color: #dddddd;
    color: #333;
    transition: 0.3s;

    &:hover {
      background-color: #333;
      color: $white;
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 20px;
      margin-bottom: 16px;
    }
  }
}

.exhibition-detail {
  &__title {
    font-size: 30px;
    margin-bottom: 10px;
  }

  &__info {
    font-size: 16px;
    color: #999;
    margin-bottom: 20px;
  }

  &__content {
    padding-bottom: 40px;
    border-bottom: 2px solid $border-color;
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 24px;
    }
  }
}

.products {
  &__title {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__aside {
    flex: 0 0 285px;
    max-width: 285px;
  }

  &__main {
    flex: 0 0 calc(100% - 380px);
    max-width: calc(100% - 380px);
  }

  @media (max-width: 991px) {
    &__aside {
      flex: 0 0 200px;
      max-width: 200px;
    }

    &__main {
      flex: 0 0 calc(100% - 220px);
      max-width: calc(100% - 220px);
    }
  }

  @media (max-width: 767px) {
    &__grid {
      flex-direction: column;
    }

    &__aside {
      order: 1;
      flex: unset;
      width: 100%;
      max-width: 200px;
      margin: 20px auto 0;
    }

    &__main {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.home {
  width: 100%;
  overflow: hidden;
  padding: 30px 30px 0;

  &__content {
    text-align: right;
  }

  &__logo {
    display: inline-flex;

    img {
      height: 35px;
    }
  }

  &__langs {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-top: 85px;
  }

  &__lang {
    display: inline-flex;

    &:not(:last-child) {
      margin-right: 10px;
    }

    img {
      height: 18px;
    }
  }

  @media (max-width: 1199px) {
    &__logo {
      img {
        height: 25px;
      }
    }

    &__langs {
      margin: 40px 0 20px;
    }
  }

  @media (max-width: 991px) {
    &__logo {
      img {
        height: 20px;
      }
    }

    &__langs {
      margin: 30px 0 20px;
    }
  }
}

.home-menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__link {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }

  &__sub {
    list-style: none;
    margin-top: 5px;
  }

  &__sub-item {
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  &__sub-link {
    font-size: 16px;
    color: #333;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }

  &__sub-2 {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__sub-2-link {
    font-style: italic;
    color: #666;

    &::before,
    &::after {
      content: ' -- ';
    }
  }

  @media (max-width: 991px) {
    &__item {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    &__link {
      font-size: 16px;
    }

    &__sublink {
      font-size: 14px;
    }
  }
}

.home-socials {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  &__item {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: #333;
    margin-right: 50px;

    &:hover {
      color: #000;
      text-decoration: none;
    }

    .fa {
      font-size: 1.4em;
    }
  }

  @media (max-width: 991px) {
    margin-bottom: 20px;

    &__item {
      font-size: 16px;
    }
  }
}

@mixin img($key) {
  @if $key == 'fit' {
    width: 100%;
    height: 100%;
  } @else if $key == 'cover' or $key == 'contain' {
    @include img('fit');
    object-fit: #{$key};
    object-position: 50% 50%;
  } @else if $key == 'bg' {
    @include img('cover');
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
  }
}

@mixin frame($key, $val: unset) {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @if $key == 'rect' {
    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: $val;
    }

    img {
      @include img('bg');
    }
  } @else if $key == 'circle' {
    @include frame('rect', 100%);
    border-radius: 50%;
  } @else if $key == 'shine' {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 70%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 40%,
        rgba(255, 255, 255, 0.1) 45%,
        rgba(255, 255, 255, 0.3) 90%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 2;
      position: absolute;
      bottom: 100%;
      left: 0;
      transform-origin: left bottom;
      transform: skewY(-30deg);
      transition: bottom 0s;
    }

    &:hover::after {
      bottom: -200%;
      transition: bottom 0.65s;
    }
  } @else if $key == 'zoom' {
    img {
      position: absolute;
      transition: 0.3s;
    }

    &:hover img {
      transform: scale(1.05);
    }
  } @else if $key == 'fade' {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.3s;
    }

    &:hover::after {
      background-color: $val;
    }
  }
}

@mixin list($key, $val: unset, $borderWidth: 1px, $borderColor: $border-color) {
  @if $key == 'unstyled' {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    @if $val != unset {
      & > li:not(:last-child) {
        margin-bottom: $val;
      }
    }
  } @else if $key == 'solid' or $key == 'dotted' or $key == 'dashed' {
    @include list('unstyled');

    @if $val != unset {
      & > li:not(:last-child) {
        padding-bottom: $val;
        margin-bottom: $val;
        border-bottom: #{$borderWidth} #{$key} $borderColor;
      }
    }
  }
}

// add limit line for text
@mixin max-line($line) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $line-height-base;

  @each $val in $line {
    -webkit-line-clamp: #{$val};
    max-height: #{$val * $line-height-base}em;
  }
}

@mixin shadow($key) {
  @if $key == 'xs' {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $key == 'sm' {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $key == 'md' {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  } @else if $key == 'lg' {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  } @else if $key == 'hover' {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
  } @else {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

@mixin btn($key, $val: unset) {
  display: inline-block;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @if $key == 'fill' {
    transition: color 0.5s;

    &:focus {
      outline: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: lightslategray;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: transform 0.5s ease-out;
      z-index: -1;
    }

    &:hover {
      color: white;
    }

    &:hover::before {
      transform: scaleX(1);
      transition-timing-function: cubic-bezier(0.45, 1.64, 0.47, 0.66);
    }
  } @else if $key == 'shine' {
    &:hover {
      text-decoration: none;
      color: #ccc;
    }

    &::before {
      content: '';
      display: block;
      width: 90%;
      height: 100%;
      background-color: white;
      opacity: 0.3;
      position: absolute;
      top: 0;
      right: 150%;
      z-index: -1;
      transform-origin: top right;
      transform: skewX(-30deg);
      transition: all 0.6s;
    }

    &:hover::before {
      right: -150%;
    }
  } @else if $key == 'filter' {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      transition: 0.2s;
    }

    &:hover::before {
      opacity: 0.1;
    }

    &:active::before {
      opacity: 0.2;
    }
  } @else if $key == 'slide' {
    // using a > span + span
    width: 150px;
    height: 30px;
    color: inherit;
    border: 1px solid $border;
    border-radius: 4px;
    cursor: pointer;
    background: 0;

    &:hover {
      color: inherit;
      text-decoration: none;
    }

    span {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: -100%;
      text-align: center;
      transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    span:last-child {
      left: 0;
    }

    &:hover span:first-child {
      left: 0;
    }

    &:hover span:last-child {
      left: 100%;
    }
  } @else if $key == ' raised' {
    // using button.class > .class__inner + span=text
    transition: all 0.1s;

    &__inner {
      display: block;
      border-radius: 50%;
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
      }
    }

    &:hover &__inner {
      animation: raised-out 0.75s;
    }
  } @else if $key == 'meon' {
  }
}

@mixin btn-neon($type) {
  color: white;
  border: 0;

  &:hover {
    color: white;
  }

  @if $type== 'orange' {
    background: linear-gradient(
      to top right,
      rgba(255, 87, 34, 1) 0%,
      rgba(251, 140, 0, 1) 100%
    );
    box-shadow: 1px 1px 30px rgba(255, 111, 0, 1);
  } @else if $type== 'blue' {
    background: linear-gradient(
      to top right,
      rgba(63, 81, 181, 1) 0%,
      rgba(3, 155, 229, 1) 40%
    );
    box-shadow: 1px 1px 30px rgba(2, 119, 189, 1);
  } @else if $type== 'green' {
    background: linear-gradient(
      to top right,
      rgba(156, 204, 101, 1) 0%,
      rgba(38, 198, 218, 1) 50%
    );
    box-shadow: 1px 1px 30px rgba(38, 198, 218, 1);
  } @else if $type== 'purple' {
    background: linear-gradient(
      to bottom right,
      rgba(63, 81, 181, 1) 0%,
      rgba(171, 71, 188, 1) 70%
    );
    box-shadow: 1px 1px 30px rgba(81, 45, 168, 1);
  } @else if $type== 'dark' {
    background: linear-gradient(
      to top right,
      rgba(117, 117, 117, 1) 0%,
      rgba(33, 33, 33, 1) 90%
    );
    box-shadow: 1px 1px 30px rgba(33, 33, 33, 1);
  }
}

@mixin text($key: 'default') {
  @if $key == 'default' {
    display: inline;
    background: 0;
    font: inherit;
    margin: 0;
    padding: 0;

    &,
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  } @else if $key == 'underline' {
    &,
    &:hover {
      text-decoration: underline;
    }
  }
}

@mixin font-face(
  $font-name,
  $file-name: $font-name,
  $weight: normal,
  $style: normal
) {
  @font-face {
    font-family: quote($font-name);
    src: url('../fonts/'+$file-name+'.woff') format('woff'),
      url('../fonts/'+$file-name+'.woff2') format('woff2');
    font-weight: $weight;
    font-style: $style;
  }
}

@keyframes raised-out {
  0% {
    width: 0;
    opacity: 0.4;
  }

  100% {
    width: 100%;
    opacity: 0;
  }
}

.cart-product {
  position: relative;

  &__remove {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 50%;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.3s;

    &:hover {
      color: $primary;
      text-decoration: none;
      background-color: $gray-300;
    }
  }

  &__frame {
    @include frame('rect', 100%);
    @include frame('fade', rgba(#fff, 0.4));
    width: 60px;
    margin-right: 10px;
  }

  &__title {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 24px;
    margin-bottom: 2px;
  }

  &__info {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 2px;
  }

  &__color {
    display: inline-block;
    width: 40px;
    height: 12px;
    border: 1px solid #444;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__price {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: auto;
    padding-left: 4px;
    text-align: right;
  }
}

.cart {
  &__backdrop {
    display: block;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: -100%;
    transition: left 0s 0.3s, opacity 0.3s;
  }

  &__wrapper {
    display: flex;
    width: 300px;
    height: 100vh;
    flex-direction: column;
    position: fixed;
    z-index: $zindex-fixed;
    top: 0;
    right: -100%;
    background-color: $white;
    transition: 0.3s;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 15px;
    border-bottom: 1px solid $border-color;
  }

  &__title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.3em;
    line-height: 1;
  }

  &__close {
    padding: 0 2px;
    background-color: transparent;
    border: 0;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;

    &:before {
      content: '\f105\f105\f105';
      font-family: 'fontawesome';
    }
  }

  &__body {
    padding: 10px 0;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__list {
    @include list('solid', 10px);

    & > li {
      padding: 0 10px;
    }
  }

  &__footer {
    border-top: 1px solid $border-color;
    padding: 20px 15px;
  }

  &__total {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &.is-show &__backdrop {
    left: 0;
    opacity: 0.5;
    transition: left 0s, opacity 0.3s;
  }

  &.is-show &__wrapper {
    right: 0;
  }
}
